import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import product from '../../../redux/actions/product';
import company from '../../../redux/actions/company';
import provider from '../../../redux/actions/provider';
import { alertError, alertSuccess, alertWarning } from '../../../utils/alert';
import { defaultValues } from '../models/formNew';

export const useFormViewModel = (props: any) => {
  const { data, handleClose, open, methods } = props;
  const { reset, getValues } = methods;
  const [providerList, setProviderList] = useState<any[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<any[]>([]);

  const loading = useAppSelector((state) => state.product.isLoadingSubmit);
  const params = useAppSelector((state) => state.product.params);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const dispatch = useAppDispatch();

  const onSubmit = async (values: any) => {
    try {
      let payloadProduct = {
        company_id: values.company_id,
        product_name: values.product_name,
        code: values.code,
        fee_fix_value: 0,
        fee_percentage: 0,
        status: !!values.status
      }
      const payloadBulkChannel = [];
      let resProduct = null,
        resChannel = null,
        errorChannel = false;

      if (data.id) {
        for (let i = 0; i < values.provider.length; i++) {
          if (!values.provider[i].provider_channel || values.provider[i].provider_channel.length === 0) errorChannel = true;
          for (let j = 0; j < values.provider[i].provider_channel.length; j++) {
            payloadBulkChannel.push({
              id: values.provider[i].provider_channel[j].id || null,
              company_payment_provider_channel_mapping_id: values.provider[i].provider_channel[j].provider_channel_id,
              fee_fix_value: parseFloat(values.provider[i].provider_channel[j].fee_fix_value),
              fee_percentage: parseFloat(values.provider[i].provider_channel[j].fee_percentage),
              status: !!values.provider[i].provider_channel[j].status
            })
          }
        };

        if (!values.provider || values.provider.length === 0) return alertWarning('Provider harus diisi');
        if (errorChannel) return alertWarning('Provider channel harus diisi');

        payloadProduct = { ...payloadProduct, fee_fix_value: data.fee_fix_value, fee_percentage: data.fee_percentage };

        resProduct = await product.updateDataNew(data.id, payloadProduct);
        resChannel = await product.updateBulkChannel(resProduct.data.id, { data: payloadBulkChannel });
      } else {
        for (let i = 0; i < values.provider.length; i++) {
          if (!values.provider[i].provider_channel || values.provider[i].provider_channel.length === 0) errorChannel = true;
          for (let j = 0; j < values.provider[i].provider_channel.length; j++) {
            payloadBulkChannel.push({
              company_payment_provider_channel_mapping_id: values.provider[i].provider_channel[j].provider_channel_id,
              fee_fix_value: parseFloat(values.provider[i].provider_channel[j].fee_fix_value),
              fee_percentage: parseFloat(values.provider[i].provider_channel[j].fee_percentage),
              status: !!values.provider[i].provider_channel[j].status
            })
          }
        };

        if (!values.provider || values.provider.length === 0) return alertWarning('Provider harus diisi');
        if (errorChannel) return alertWarning('Provider channel harus diisi');

        resProduct = await product.createDataNew(payloadProduct);
        resChannel = await product.createBulkChannel(resProduct.data.id, { data: payloadBulkChannel });
      }

      alertSuccess(`Product berhasil di ${data.id ? 'update' : 'tambahkan'}`).then(() => {
        setSelectedProvider((selectedProvider || []).map(() => ''));
        dispatch(product.fetchList(params));
        handleClose();
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  const handleFetchProvider = async () => {
    try {
      const res: any = await dispatch(provider.fetchAll());

      setProviderList((res.data || []).map((val: any) => ({ label: val.name, value: val.id, disabled: false })));
      setSelectedProvider((res.data || []).map(() => ''));
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  }

  const handleChangeCompany = () => {
    const currentField = getValues();

    reset({
      product_name: currentField.product_name,
      code: currentField.code,
      company_id: currentField.company_id,
      provider: (currentField.provider || []).map((val: any) => ({ provider_id: val.provider_id })),
      status: currentField.status,
    })
  }

  useEffect(() => {
    dispatch(company.fetchAll());
    handleFetchProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.id && open === true) {
      reset(data);

      if (data.provider.length > 0) {
        const temp = providerList.map((_: any, i: number) => data.provider[i] ? data.provider[i].provider_id : '');

        setSelectedProvider(temp);
      }
    } else {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChangeProvider = (id: any, index: number) => {
    const temp = [...selectedProvider];
    temp[index] = id;

    setSelectedProvider(temp);

    const currentField = getValues();
    const deepCloneFields = JSON.parse(JSON.stringify(currentField));
    if (deepCloneFields.provider && deepCloneFields.provider.length > 0) {
      const newFields = {
        code: deepCloneFields.code,
        company_id: deepCloneFields.company_id,
        product_name: deepCloneFields.product_name,
        status: deepCloneFields.status,
        provider: deepCloneFields.provider.map((val: any) => String(val.provider_id) === String(id) ? ({
          provider_id: val.provider_id,
          provider_channel: [],
        }) : ({ ...val }))
      }

      reset(newFields);
    }
  }

  const handleDeleteProvider = (index: number) => {
    const temp = [...selectedProvider];
    temp.splice(index, 1);
    temp.push('')

    setSelectedProvider(temp);
  }

  const validateFieldArray = (errors: any, index: number) => {
    if (errors.provider && errors.provider[index] && errors.provider[index].provider_id) {
      return errors.provider[index].provider_id.message
    }

    return '';
  }

  const resetSelectedProvider = () => setSelectedProvider((providerList || []).map(() => ''));

  const getProviderList = () => providerList.map(val => ({ ...val, disabled: selectedProvider.includes(val.value) }));

  return {
    providerList: getProviderList(),
    loading,
    params,
    companyList,
    onSubmit,
    handleChangeProvider,
    selectedProvider,
    validateFieldArray,
    resetSelectedProvider,
    handleChangeCompany,
    handleDeleteProvider,
  }
}