import { Delete, History, Visibility } from "@mui/icons-material"
import { Button, Chip, Grid, Tooltip } from "@mui/material"
import { Box } from "@mui/system"
import { GridColDef } from "@mui/x-data-grid"
import { formatMoney } from "../../../utils/string"
import { formatDateTime } from "../../../utils/time"

export const cashOutColumns = (
    role: any,
    handleHistory: (data: any) => void,
    handleDetail: (data: any) => void,
    handleTransfer: (data: any) => void,
    handleApprove: (data: any) => void,
    handleReject: (data: any) => void,
    actionWidth: number
): GridColDef[] => {
    return [
        {
            field: 'action',
            headerName: 'Action',
            width: actionWidth,
            sortable: false,
            filterable: false,
            renderCell: ({ row }) => (
                <Grid container alignItems={"center"}>
                    {role.view && <Tooltip title="Detail" sx={{ mr: 1 }} onClick={() => handleDetail(row)}>
                        <Visibility fontSize="small" className="pointer" color="primary" />
                    </Tooltip>}
                    {role.view && <Tooltip title="History" sx={{ mr: 1 }} onClick={() => handleHistory(row)}>
                        <History fontSize="small" className="pointer" color="info" />
                    </Tooltip>}
                    {((row.status === 'ready' || row.status ==='failed')  && role.transfer) &&
                        <Button variant="outlined" sx={{ mr: 1 }} size="small" color="info"
                            onClick={() => handleTransfer(row)}>
                            Ajukan Tranfer
                        </Button>}
                    {row.status === 'requested' &&
                        <Box>
                            {role.approve &&
                                <Button variant="outlined" sx={{ mr: 1 }} size="small" color="success"
                                    onClick={() => handleApprove(row)}>
                                    Terima
                                </Button>}
                            {role.reject &&
                                <Button variant="outlined" size="small" color="error" onClick={() => handleReject(row)}>
                                    Tolak
                                </Button>}
                        </Box>}
                </Grid>
            )
        },
        {
            field: 'status',
            headerName: 'Cash Out Status',
            width: 200,
            renderCell: ({ value }) => <Chip size="small" sx={{ textTransform: 'capitalize' }}
                label={value.replace(/_/g, ' ')} />
        },
        { field: 'partner_name', headerName: 'Partner Name', width: 300 },
        { field: 'batch_number', headerName: 'Batch Number', width: 250 },
        { field: 'grand_total', headerName: 'Grand Total', width: 150, renderCell: ({ value }) => formatMoney(value) },
        { field: 'transaction_fee', headerName: 'Transaction Fee', width: 150, renderCell: ({ value }) => formatMoney(value) },
        { field: 'company_name', headerName: 'Company Name', width: 200 },
        { field: 'partner_bank_name', headerName: 'Partner Bank Name', width: 150 },
        { field: 'partner_account_name', headerName: 'Bank Account Name', width: 150 },
        { field: 'partner_account_number', headerName: 'Partner Bank Account', width: 180 },
        { field: 'provider_name', headerName: 'Payment Provider', width: 150, renderCell: ({ value }) => value || '-' },
        { field: 'channel_name', headerName: 'Payment Channel', width: 150 },
        { field: 'created_at', headerName: 'Created Date', width: 200, renderCell: ({ value }) => formatDateTime(value) },
        {
            field: 'settlement_date',
            headerName: 'Settlement Date',
            width: 200,
            renderCell: ({ value }) => formatDateTime(value)
        },
    ]
}

export const cashOutHistoryColumns = (): GridColDef[] => {
    return [
        {
            field: 'provider_name',
            headerName: 'Payment Provider',
            flex: 1,
            minWidth: 150,
            renderCell: ({ value }) => value || '-'
        },
        { field: 'channel_name', headerName: 'Payment Channel', flex: 1, minWidth: 150 },
        {
            field: 'partner_account_number',
            headerName: 'Partner Bank Account',
            flex: 1,
            minWidth: 200,
            renderCell: ({ value }) => value || '-'
        },
        {
            field: 'created_at',
            headerName: 'Date',
            minWidth: 200,
            flex: 1,
            renderCell: ({ value }) => formatDateTime(value)
        },
        {
            field: 'status',
            headerName: 'Payment Status',
            flex: 1, minWidth: 150,
            renderCell: ({ value }) => <Chip size="small" sx={{ textTransform: 'capitalize' }}
                label={value.replace(/_/g, ' ')} />
        },
    ]
}

export const feeLaterColumns = (): GridColDef[] => {
    return [
        { field: 'batch_number', headerName: 'Batch Number', width: 200 },
        { field: 'partner_name', headerName: 'Partner Name', flex: 1, minWidth: 150 },
        {
            field: 'admin_fee',
            headerName: 'Admin Fee',
            flex: 1,
            minWidth: 130,
            renderCell: ({ value }) => formatMoney(value)
        },
        {
            field: 'settlement_date',
            headerName: 'Settlement Date',
            flex: 1,
            minWidth: 160,
            renderCell: ({ value }) => formatDateTime(value)
        },
        {
            field: 'status',
            headerName: 'Payment Status',
            minWidth: 180,
            flex: 1,
            renderCell: ({ value }) => <Chip size="small" sx={{ textTransform: 'capitalize' }}
                label={value.replace(/_/g, ' ')} />
        },
    ]
}

export const cashOutItemsColumns = (handleDelete: (data: any) => void, isDeleteable: boolean = false): GridColDef[] => {
    const columnAction = !isDeleteable ? [] : [
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (data: any) => (
                <Grid container>
                    <Tooltip title="Delete">
                        <Delete fontSize="small" className="pointer" onClick={() => handleDelete(data.row)}
                            color="error" />
                    </Tooltip>
                </Grid>
            )
        }
    ]

    return [
        { field: 'item_name', headerName: 'Product Name', flex: 1, minWidth: 150 },
        {
            field: 'item_grand_total',
            headerName: 'Total',
            flex: 1,
            minWidth: 100,
            renderCell: ({ value }) => formatMoney(value)
        },
        { field: 'invoice_number', headerName: 'Invoice Number', flex: 1, minWidth: 300 },
        { field: 'ref_invoice_number', headerName: 'Invoice Number Item', flex: 1, minWidth: 300, renderCell: ({ value }) => value || '-' },
        { field: 'payment_provider_name', headerName: 'Provider Name', flex: 1, minWidth: 150 },
        { field: 'payment_channel_name', headerName: 'Channel Name', flex: 1, minWidth: 150 },
        {
            field: 'settlement_date',
            headerName: 'Settlement Date',
            flex: 1,
            minWidth: 180,
            renderCell: ({ value }) => formatDateTime(value)
        },
        { field: 'note', headerName: 'Note', flex: 1, minWidth: 200, renderCell: ({ value }) => value || '-' },
        ...columnAction,
    ]
}

export const cashinInvoiceColumns = (): GridColDef[] => {
    return [
        { field: 'invoice_number', headerName: 'Invoice Number', flex: 1, minWidth: 300 },
        { field: 'customer_name', headerName: 'Customer Name', flex: 1, minWidth: 130 },
        {
            field: 'total_amount',
            headerName: 'Total Pembayaran',
            flex: 1,
            minWidth: 160,
            renderCell: ({ value }) => formatMoney(value)
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            minWidth: 200,
            flex: 1,
            renderCell: ({ value }) => formatDateTime(value)
        },
    ]
}