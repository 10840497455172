import httpService from "../../../adapters/httpService";
import {URL_API} from "../../../configs";
import { alertError } from "../../../utils/alert";
import baseActions from "../baseActions";

const path = 'provider/channel/mapping';

export const actionType = (action: string) => {
    return {
        request: `${action}_${path}_request`.toUpperCase(),
        success: `${action}_${path}_success`.toUpperCase(),
        error: `${action}_${path}_error`.toUpperCase()
    }
}
const http = httpService();
const index = {
    ...baseActions(path, URL_API, actionType),
    fetchListNew: (params?: any) => {
        return (dispatch: any) => {
            dispatch({type: actionType('fetchListNew').request});
            return http.get(`${URL_API}/${path}`, {params}).then(
                response => {
                    dispatch({type: actionType('fetchListNew').success, response: response.data, params});
                    return response.data;
                },
                error => {
                    alertError(error.response?.data?.message || 'Maaf, terjadi kesalahan');
                    dispatch({type: actionType('fetchListNew').error});
                    throw error.response;
                }
            );
        };
    },
    fetchDetailNew: (id: any) => {
        return http.get(`${URL_API}/${path}/${id}`).then(
            response => {
                return response.data;
            },
            error => {
                throw error.response;
            }
        );
    },
};
export default index;
