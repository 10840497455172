import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { InputField, SelectField, SwitchField } from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import { IProviderForm, providerDefaultValues, providerResolver } from './models/form';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import provider from '../../redux/actions/provider';
import LoadingButton from '@mui/lab/LoadingButton';
import { Add, Clear, Save } from '@mui/icons-material';
import { Grid, IconButton, InputLabel } from '@mui/material';

const ProviderFormDialog = ({ open, handleClose, data }: any) => {
  const methods = useForm<IProviderForm>({ resolver: providerResolver });
  const { fields, append, remove } = useFieldArray({
    control: methods.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'secret_templates', // unique name for your Field Array
    // keyName: "id", default to "id", you can change the key name
  });
  const loading = useAppSelector((state) => state.provider.isLoadingSubmit);
  const params = useAppSelector((state) => state.provider.params);
  const providers = useAppSelector((state) => state.provider.providerDefaults);
  const dispatch = useAppDispatch();
  const isEdit = data?.id ? true : false;

  const onSubmit = async (value: any) => {
    try {
      if (data?.id) {
        await dispatch(provider.updateData(data?.id, value));
      } else {
        await dispatch(provider.createData(value));
      }
      alertSuccess(`Provider berhasil di ${data?.id ? 'update' : 'tambahkan'}`).then(() => {
        handleClose();
        dispatch(provider.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  React.useEffect(() => {
    dispatch(provider.getProviderDefault());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data?.id && open === true) {
      methods.reset(data);
    } else {
      methods.reset(providerDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{data?.id ? 'Edit' : 'Add'} Provider</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <SelectField
                name="name"
                label="Provider Name"
                disabled={isEdit}
                options={providers.map((data: any) => ({ value: data.name, label: data.name }))}
              />
              <InputField name="code" label="Provider Code" />
              <SwitchField name="status" label="Aktif" />
              <InputLabel sx={{ mb: 1, fontWeight: 'bold' }}>Secret Templates</InputLabel>
              {fields.map((field, index) => {
                return (
                  <Grid container spacing={1} key={field.id}>
                    <Grid item sm={7}>
                      <InputField name={`secret_templates.${index}.name`} label="Name" disabled={isEdit} />
                    </Grid>
                    <Grid item sm={4}>
                      <SelectField
                        name={`secret_templates.${index}.type`}
                        label="Type"
                        disabled={isEdit}
                        options={[
                          { label: 'String', value: 'string' },
                          { label: 'Number', value: 'number' },
                        ]}
                      />
                    </Grid>
                    {!isEdit && (
                      <Grid item sm={1}>
                        <IconButton
                          sx={{ mt: '26px' }}
                          onClick={() => remove(index)}
                          color="error"
                          size="small">
                          <Clear />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
              {!isEdit && (
                <Button
                  onClick={() => {
                    append({
                      name: '',
                      type: 'string',
                    });
                  }}
                  variant="outlined"
                  color="info"
                  startIcon={<Add />}
                  type="button">
                  Add
                </Button>
              )}
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ProviderFormDialog;
