import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, string } from 'yup';

export interface IProviderForm {
  company_id: string;
  payment_provider_id: string;
  provider_secrets: string;
  status: boolean;
}

export const providerDefaultValues = {
  payment_provider_id: '',
  company_id: '',
  provider_secrets: '',
  status: true,
};

export const providerResolver = yupResolver(
  object({
    payment_provider_id: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    company_id: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
  }),
);

export interface IChannelForm {
  company_id: number;
  company_name: string;
  payment_provider_id: number;
  provider_name: string;
  provider_code: string;
  fee_fix_value: string;
  fee_percentage: string;
  expired_time: number;
  status: boolean;
  fee_type: string,
  is_fix_virtual_account?: boolean;
  capability?: number;
  channel_type_configs: any
}

export const channelDefaultValues = {
  company_id: 0,
  company_name: '',
  payment_provider_id: 0,
  provider_name: '',
  provider_code: '',
  fee_fix_value: '',
  fee_percentage: '',
  expired_time: 0,
  status: true,
  is_fix_virtual_account: false,
  capability: 1,
  channel_type_configs: null
};

export const channelResolver = yupResolver(
  object({
    provider_code: string().required('Required field'),
    capability: number(),
    fee_fix_value: number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(0, 'Must be greater than or equal to 0'),
    fee_percentage: number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(0, 'Must be greater than or equal to 0')
      .max(100, 'Must be less than or equal to 100'),
    expired_time: number().when('capability', {
      is: '1',
      then: number().transform((value) => (isNaN(value) ? 0 : value)).min(1, 'Must be greater than 0'),
      otherwise: number(),
    }),
  }),
);
